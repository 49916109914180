/* eslint-disable no-underscore-dangle */
import React from "react"
import {MapToComponents} from "react-map-to-components"
import BasicSliderSection from "Sections/BasicSliderSection/BasicSliderSection"
import DefaultSection from "Sections/DefaultSection/DefaultSection"
import TextSection from "Sections/TextSection/TextSection"

function FlexibleContent({content}) {
  return (
    <div className="flexible-content-wrapper">
      {
        content.length > 0 && (
          <MapToComponents
            getKey={(x) => x.flexibleContentId}
            getType={(x) => x.__typename}
            list={content}
            default={DefaultSection}
            map={{
              WordPressAcf_basic_slider_section: BasicSliderSection,
              WordPressAcf_text_section: TextSection
            }}
            mapDataToProps={{
              WordPressAcf_basic_slider_section: ({data}) => ({images: data.images}),
              WordPressAcf_text_section: ({data}) => ({text: data.text}),
            }}
          />
        )
      }
    </div>
  )
}

export default FlexibleContent

import React from "react"
// import "./DefaultSection.scss"

const DefaultSection = () => {
  return (
    <>
    </>
  )
}

export default DefaultSection
